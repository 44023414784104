import PromotionQuizCalculator from "./PromotionQuizCalculator.svelte";
import SignUpForm from "./auth/SignUpForm.svelte";
import SignInForm from "./auth/SignInForm.svelte";
import PasswordResetForm from "./auth/PasswordResetForm.svelte";
import ChangePasswordForm from "./auth/ChangePasswordForm.svelte";
import ZencpaModalWidget from "./ZencpaModalWidget.svelte";
import ZencpaSpyModalWidget from "./ZencpaSpyModalWidget.svelte";
import SupportRequestWidget from "./support/SupportRequestWidget.svelte";
import SupportRequestWidgetRu from "./support/SupportRequestWidgetRu.svelte";

function createPromotionQuizCalculator(wrapId, params = {}) {
	return new PromotionQuizCalculator({
		target: document.getElementById(wrapId),
		props: params
	});
}

window['createPromotionQuizCalculator'] = createPromotionQuizCalculator;

window['signUpFormRegister'] = (target, props) => {
	return new SignUpForm({target, props});
};

window['signInFormRegister'] = (target, props) => {
	return new SignInForm({target, props});
};

window['supportRequestWidgetRegister'] = (target, props) => {
	return new SupportRequestWidget({target, props});
};
window['supportRequestWidgetRuRegister'] = (target, props) => {
	return new SupportRequestWidgetRu({target, props});
};

window['passwordResetFormRegister'] = (target, props) => {
	return new PasswordResetForm({target, props});
};

window['changePasswordFormRegister'] = (target, props) => {
	return new ChangePasswordForm({target, props});
};

window['createZencpaModalWidget'] = (target, props) => {
	return new ZencpaModalWidget({target, props});
};
window['createZencpaSpyModalWidget'] = (target, props) => {
	return new ZencpaSpyModalWidget({target, props});
};
window['createZencpaSpyModalWidget'] = (target, props) => {
	return new ZencpaSpyModalWidget({target, props});
};